import { useSignal } from "@preact/signals";

export default function RegisterCodeCheck({login, password, code, changeForm}: {login: string, password: string, code: string, changeForm: (a: string) => void}) {
    const enteredCode = useSignal("")
    const error = useSignal(false)

    async function sendCode() {
        if (enteredCode.value != code) {
            error.value = true
            return
        }

        await fetch("/api/login", {
            method: "OPTIONS",
            body: JSON.stringify({
                login, password        
            })
        })

        changeForm("login")
    }

    return (
        <div class="card-body mb-0">
            <h4 class="text-2xl font-semibold">Подтверждение почты</h4>
            {error.value && <div class="alert alert-danger text-center mx-auto">Что-то не так! Попробуйте еще раз!</div>}

            <p>На почту {login} был отправлен код подтверждения, введите его, чтобы пользоваться всеми возможностями сайта на полную! </p>
            <input type="number" placeholder="Код" class="form-control mt-3" value={enteredCode.value} onInput={e => enteredCode.value = e.currentTarget.value} />
            <button onClick={() => sendCode()} class="btn btn-primary mb-0 mt-2" style="padding: 11px 24px">Подтвердить</button>
        </div>
    )
}